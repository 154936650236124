import React, { useState } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  Modal,
  Reveal,
} from "semantic-ui-react";
import xprizeImage from "../images/projects/xpf.jpg";
import apaImage from "../images/projects/apa.jpg";
import uclaImage from "../images/projects/ucla.jpg";
import migaImage from "../images/projects/miga.jpg";
import eatfreshImage from "../images/projects/eatfresh.jpg";
import monotimeImage from "../images/projects/monotime.jpg";
import recipeImage from "../images/projects/recipebook.jpg";
import xprizehome from "../images/projects/xpf-home.jpg";
import apahome from "../images/projects/apa-homepage.jpg";
import uclahome from "../images/projects/ucla-getting-started.jpg";
import migahome from "../images/projects/miga-homepage.jpg";
import eatfreshhome from "../images/projects/eatfresh-full.jpg";
import monotimehome from "../images/projects/MonoTime-full.jpg";
import recipehome from "../images/projects/RecipeBook-full.jpg";
import "./projects.scss";

const Projects = () => {
  const [firstOpen, setFirstOpen] = useState(false);
  const [secondOpen, setSecondOpen] = useState(false);
  const [thirdOpen, setThirdOpen] = useState(false);
  const [forthOpen, setForthOpen] = useState(false);
  const [fifthOpen, setFifthOpen] = useState(false);
  const [sixthOpen, setSixthOpen] = useState(false);
  const [seventhOpen, setSeventhOpen] = useState(false);

  return (
    <Container className="projects">
      <Header as="h2">Projects</Header>
      <Grid columns="4" stackable>
        <Grid.Row>
          {/* Start XPRIZE */}
          <Grid.Column className="projects-grid">
            <Reveal animated="fade">
              <Reveal.Content visible>
                <Image src={xprizeImage} size="massive" />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Image src={xprizeImage} size="massive" />
                <div className="overlay">
                  <div className="project-title">
                    <Header as="h4">XPRIZE</Header>
                    <p>Drupal 7</p>
                  </div>
                  <div className="project-link">
                    <Button onClick={() => setFirstOpen(true)}>
                      Learn More
                    </Button>
                  </div>
                </div>
              </Reveal.Content>
            </Reveal>
          </Grid.Column>
          {/* END XPRIZE */}
          {/* Start Americas Promise */}
          <Grid.Column className="projects-grid">
            <Reveal animated="fade">
              <Reveal.Content visible>
                <Image src={apaImage} size="massive" />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Image src={apaImage} size="massive" />
                <div className="overlay">
                  <div className="project-title">
                    <Header as="h4">Americas Promise</Header>
                    <p>Drupal 8 / Angularjs / Google API’s</p>
                  </div>
                  <div className="project-link">
                    <Button onClick={() => setSecondOpen(true)}>
                      Learn More
                    </Button>
                  </div>
                </div>
              </Reveal.Content>
            </Reveal>
          </Grid.Column>
          {/* END Americas Promise */}
          {/* Start UCLA IT Services */}
          <Grid.Column className="projects-grid">
            <Reveal animated="fade">
              <Reveal.Content visible>
                <Image src={uclaImage} size="massive" />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Image src={uclaImage} size="massive" />
                <div className="overlay">
                  <div className="project-title">
                    <Header as="h4">UCLA IT Services</Header>
                    <p>Drupal 8</p>
                  </div>
                  <div className="project-link">
                    <Button onClick={() => setThirdOpen(true)}>
                      Learn More
                    </Button>
                  </div>
                </div>
              </Reveal.Content>
            </Reveal>
          </Grid.Column>
          {/* END UCLA IT Services */}
          {/* Start MIGA */}
          <Grid.Column className="projects-grid">
            <Reveal animated="fade">
              <Reveal.Content visible>
                <Image src={migaImage} size="massive" />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Image src={migaImage} size="massive" />
                <div className="overlay">
                  <div className="project-title">
                    <Header as="h4">MIGA</Header>
                    <p>Drupal 8</p>
                  </div>
                  <div className="project-link">
                    <Button onClick={() => setForthOpen(true)}>
                      Learn More
                    </Button>
                  </div>
                </div>
              </Reveal.Content>
            </Reveal>
          </Grid.Column>
          {/* END MIGA */}
        </Grid.Row>
        <Grid.Row>
          {/* Start EatFresh */}
          <Grid.Column className="projects-grid">
            <Reveal animated="fade">
              <Reveal.Content visible>
                <Image src={eatfreshImage} size="massive" />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Image src={eatfreshImage} size="massive" />
                <div className="overlay">
                  <div className="project-title">
                    <Header as="h4">EatFresh</Header>
                    <p>Drupal 7/8 / GatsbyJs / React / Firebase</p>
                  </div>
                  <div className="project-link">
                    <Button onClick={() => setFifthOpen(true)}>
                      Learn More
                    </Button>
                  </div>
                </div>
              </Reveal.Content>
            </Reveal>
          </Grid.Column>
          {/* END EatFresh */}
          {/* Start MonoTime */}
          <Grid.Column className="projects-grid">
            <Reveal animated="fade">
              <Reveal.Content visible>
                <Image src={monotimeImage} size="massive" />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Image src={monotimeImage} size="massive" />
                <div className="overlay">
                  <div className="project-title">
                    <Header as="h4">MonoTime</Header>
                    <p>React</p>
                  </div>
                  <div className="project-link">
                    <Button onClick={() => setSixthOpen(true)}>
                      Learn More
                    </Button>
                  </div>
                </div>
              </Reveal.Content>
            </Reveal>
          </Grid.Column>
          {/* END MonoTime */}
          {/* Start RecipeBook */}
          <Grid.Column className="projects-grid">
            <Reveal animated="fade">
              <Reveal.Content visible>
                <Image src={recipeImage} size="massive" />
              </Reveal.Content>
              <Reveal.Content hidden>
                <Image src={recipeImage} size="massive" />
                <div className="overlay">
                  <div className="project-title">
                    <Header as="h4">RecipeBook</Header>
                    <p>React / Nodejs / Sequelize / Heroku</p>
                  </div>
                  <div className="project-link">
                    <Button onClick={() => setSeventhOpen(true)}>
                      Learn More
                    </Button>
                  </div>
                </div>
              </Reveal.Content>
            </Reveal>
          </Grid.Column>
          {/* END RecipeBook */}
        </Grid.Row>
      </Grid>
      {/* Start XPRIZE */}
      <Modal
        onClose={() => setFirstOpen(false)}
        onOpen={() => setFirstOpen(true)}
        open={firstOpen}
      >
        <Modal.Header>XPRIZE</Modal.Header>
        <Modal.Content image>
          <div className="image">
            <Image className="project-image" src={xprizehome} size="small" />
          </div>
          <Modal.Description>
            <strong>Working Under:</strong> ActiveLAMP <br />
            <strong>When:</strong> 2013-2018
            <ul>
              <li>~100,000 visitors/month</li>
              <li>Managed multiple sites on Drupal 7 and Drupal 8</li>
              <li>
                Migrated multiple sites to one Drupal 8 site that I was lead in
                building
              </li>
            </ul>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setFirstOpen(false)} primary>
            Done <Icon name="right chevron" />
          </Button>
        </Modal.Actions>
      </Modal>
      {/* END XPRIZE */}
      {/* Start Americas Promise */}
      <Modal
        onClose={() => setSecondOpen(false)}
        onOpen={() => setSecondOpen(true)}
        open={secondOpen}
      >
        <Modal.Header>Americas Promise</Modal.Header>
        <Modal.Content image>
          <div className="image">
            <Image className="project-image" src={apahome} size="medium" />
          </div>
          <Modal.Description>
            <strong>Working Under:</strong> ActiveLAMP <br />
            <strong>When:</strong> 2016-present
            <ul>
              <li>&gt; 30,000 visitors/month</li>
              <li>Migrated data from Drupal 7 to Drupal 8</li>
              <li>Build out paragraphs for pages</li>
              <li>
                Wrote a plugin to pull images from a selected Google image album
              </li>
            </ul>
            <strong>View Site:</strong>{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://americaspromise.org"
            >
              Americas Promise
            </a>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setSecondOpen(false)} primary>
            Done <Icon name="right chevron" />
          </Button>
        </Modal.Actions>
      </Modal>
      {/* END Americas Promise */}
      {/* Start UCLA IT Services */}
      <Modal
        onClose={() => setThirdOpen(false)}
        onOpen={() => setThirdOpen(true)}
        open={thirdOpen}
      >
        <Modal.Header>UCLA IT Services</Modal.Header>
        <Modal.Content image>
          <div className="image">
            <Image className="project-image" src={uclahome} size="medium" />
          </div>
          <Modal.Description>
            <strong>Working Under:</strong> ActiveLAMP <br />
            <strong>When:</strong> 2016-2018
            <ul>
              <li>~300,000 visitors/month</li>
              <li>
                Assisted in the building of Drupal 8 site theme for multisite
              </li>
              <li>Built two separate themes for the sites</li>
            </ul>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setThirdOpen(false)} primary>
            Done <Icon name="right chevron" />
          </Button>
        </Modal.Actions>
      </Modal>
      {/* END UCLA IT Services */}
      {/* Start MIGA */}
      <Modal
        onClose={() => setForthOpen(false)}
        onOpen={() => setForthOpen(true)}
        open={forthOpen}
      >
        <Modal.Header>MIGA</Modal.Header>
        <Modal.Content image>
          <div className="image">
            <Image className="project-image" src={migahome} size="medium" />
          </div>
          <Modal.Description>
            <strong>Working Under:</strong> ActiveLAMP <br />
            <strong>When:</strong> 2018-2020
            <ul>
              <li>&gt; 30,000 visitors/month </li>
              <li>Built paragraphs for pages</li>
              <li>
                Setup pages so that they could use the same page for different
                types
              </li>
              <li>
                Was lead developer on the project making sure pull requests were
                tested and working correctly
              </li>
            </ul>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setForthOpen(false)} primary>
            Done <Icon name="right chevron" />
          </Button>
        </Modal.Actions>
      </Modal>
      {/* END MIGA */}
      {/* Start EatFresh */}
      <Modal
        onClose={() => setFifthOpen(false)}
        onOpen={() => setFifthOpen(true)}
        open={fifthOpen}
      >
        <Modal.Header>EatFresh</Modal.Header>
        <Modal.Content image>
          <div className="image">
            <Image className="project-image" src={eatfreshhome} size="medium" />
          </div>
          <Modal.Description>
            <strong>Working Under:</strong> ActiveLAMP <br />
            <strong>When:</strong> 2018-present
            <ul>
              <li>&gt; 15,000 visitors/month</li>
              <li>Migrated data from Drupal 7 to Drupal 8</li>
              <li>Migrated users to Firebase</li>
              <li>
                Migrated likes to Firebase and setup a cloud function to handle
                liking and unliking items
              </li>
              <li>Helped build Gatsby/React frontend</li>
            </ul>
            <strong>View Site:</strong>{" "}
            <a target="_blank" rel="noreferrer" href="https://eatfresh.org">
              EatFresh
            </a>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setFifthOpen(false)} primary>
            Done <Icon name="right chevron" />
          </Button>
        </Modal.Actions>
      </Modal>
      {/* END EatFresh */}
      {/* Start MonoTime */}
      <Modal
        onClose={() => setSixthOpen(false)}
        onOpen={() => setSixthOpen(true)}
        open={sixthOpen}
      >
        <Modal.Header>MonoTime</Modal.Header>
        <Modal.Content image>
          <div className="image">
            <Image className="project-image" src={monotimehome} size="medium" />
          </div>
          <Modal.Description>
            <strong>Working Under:</strong> Westman Interactive <br />
            <strong>When:</strong> 2020-present <br /> <br />
            <p>
              MonoTime was created as a personal project. It is a time
              calculator to show time during worked hours to make sure that
              timesheets are correct. The app is built using React.
            </p>
            <strong>View Site:</strong>{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://monotime.westmaninteractive.com"
            >
              MonoTime
            </a>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setSixthOpen(false)} primary>
            Done <Icon name="right chevron" />
          </Button>
        </Modal.Actions>
      </Modal>
      {/* END MonoTime */}
      {/* Start RecipeBook */}
      <Modal
        onClose={() => setSeventhOpen(false)}
        onOpen={() => setSeventhOpen(true)}
        open={seventhOpen}
      >
        <Modal.Header>RecipeBook</Modal.Header>
        <Modal.Content image>
          <div className="image">
            <Image className="project-image" src={recipehome} size="medium" />
          </div>
          <Modal.Description>
            <strong>Working Under:</strong> Westman Interactive <br />
            <strong>When:</strong> 2020-present <br /> <br />
            <p>
              RecipeBook was created as a personal project and is still being
              developed. The intended purpose is a recipe and shopping list
              expreience that would allow the user to create a shopping list
              from recipes and keep track of costs. The app is built using
              React, NodeJs, Google Authentication and a Postgres database
            </p>
            <strong>View Site:</strong>{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://recipe.westmaninteractive.com"
            >
              RecipeBook
            </a>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setSeventhOpen(false)} primary>
            Done <Icon name="right chevron" />
          </Button>
        </Modal.Actions>
      </Modal>
      {/* END RecipeBook */}
    </Container>
  );
};

export default Projects;
