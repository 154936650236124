import React from "react";
import AboutText from "./about";
import Projects from "./projects";
import Contact from "./contact";
import HeroImage from "./heroimage";
import MainMenu from "./mainmenu";

function App() {
  return (
    <div className="App">
      <div>
        <HeroImage />
      </div>
      <MainMenu />
      <AboutText />
      <Projects />
      <Contact />
    </div>
  );
}

export default App;
