import React from "react";
import { Header } from "semantic-ui-react";
import "./heroimage.scss";

const HeroImage = () => (
  <div className="hero">
    <div className="hero-active">
      <div className="hero-overlay">
        <Header as="h1" inverted>
          Jonathan Westman
        </Header>
        <p className="hero-overlay--description">
          Web Developer based in the Greater Salt Lake area of Utah
        </p>
      </div>
    </div>
  </div>
);

export default HeroImage;
