import React, { Fragment, useState } from "react";
import { scroller } from "react-scroll";

import { Container, Menu, Visibility } from "semantic-ui-react";

const MainMenu = ({ menuCallback, aboutRef, projectsRef, contactRef }) => {
  const [menuFixed, setMenuFixed] = useState(0);

  const scrollToSection = (section) => {
    scroller.scrollTo(section, {
      duration: 800,
      delay: 0,
      offset: -50,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <Fragment>
      <Visibility
        onBottomPassed={() => setMenuFixed(1)}
        onBottomVisible={() => setMenuFixed(0)}
        once={false}
      >
        <Menu
          stackable
          borderless
          fixed={menuFixed ? "top" : undefined}
          // style={menuFixed ? fixedMenuStyle : menuStyle}
        >
          <Container>
            <Menu.Item header>Jonathan Westman</Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item as="a" onClick={() => scrollToSection("about")}>
                About
              </Menu.Item>
              <Menu.Item as="a" onClick={() => scrollToSection("contact")}>
                Projects
              </Menu.Item>
              <Menu.Item as="a" onClick={() => scrollToSection("projects")}>
                Contact
              </Menu.Item>
            </Menu.Menu>
          </Container>
        </Menu>
      </Visibility>
    </Fragment>
  );
};

export default MainMenu;
