import React from "react";
import { Container, Grid, Header, List } from "semantic-ui-react";
import "./contact.scss";

const Contact = () => (
  <div className="contact">
    <Container>
      <Grid columns="2" stackable verticalAlign="bottom">
        <Grid.Row>
          <Grid.Column>
            <List>
              <List.Item>
                <Header as="h2">Contact</Header>
              </List.Item>
              <List.Item>
                <List.Icon name="mail" />
                <List.Content>
                  <a href="j.westman@gmail.com">Email</a>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="github" />
                <List.Content>
                  <a href="https://github.com/jonathanwestman">GitHub</a>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="linkedin" />
                <List.Content>
                  <a href="https://www.linkedin.com/in/jonathanwestman/">
                    LinkedIn
                  </a>
                </List.Content>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column className="copyright">
            <p>© 2021 Jonathan Westman. All rights reserved.</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </div>
);

export default Contact;
