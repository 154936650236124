import React from "react";
import { Container, Grid, Image } from "semantic-ui-react";
import profileImage from "../images/jonathanwestman.jpeg";
import "./about.scss";

const AboutText = () => (
  <Container className="about">
    <Grid stackable reversed="tablet computer">
      <Grid.Row>
        <Grid.Column width={8}>
          <Image
            className="about-image"
            src={profileImage}
            size="large"
            circular
            centered
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <p>
            I’m a web developer with more than eight years of experience working
            on enterprise websites with 20,000+ monthly visitors and SASS
            projects along side the ActiveLAMP team. I currently specialize in
            Drupal development with experience in different frontend technolgies
            and backend services.
          </p>
          <p>
            I am responsible for maintaining multiple Drupal projects in various
            stages of their lifecycle. I use my knowledge to help others solve
            problems in Drupal and continue to improve the user experience of
            the Drupal projects that I maintain and build.
          </p>
          <p>
            I graduated from the Art Institute in 2011 with a Bachelor's Degree
            in Web Design and Interactive Media. I am inspired daily by my wife
            and our daughter. In my free time I like to hike, camp, cook, play
            video games and work on personal projects.
          </p>
          <h3>TLDR;</h3>
          <ul>
            <li>Bachelor’s Degree in Web Design and Interactive Media</li>
            <li>8+ years of experience</li>
            <li>
              8+ enterprise websites using Drupal and other frontend
              technologies
            </li>
            <li>
              Built and maintain sites with over 20,000+ unique monthly users
            </li>
          </ul>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);

export default AboutText;
